
<style scoped lang="less">
.Student_Move {
  background: #fff;
  padding: 20px;
  box-shadow: @shadow;
  .item {
    display: flex;
    align-items: center;
    margin-top: 20px;
    .el-select {
      width: 220px;
      margin: 0 10px;
    }
  }
}
</style>
<template>
  <div class="Student_Move">
    <div class="stu-module-header">
      <div class="stu-module-title">学籍异动</div>
<!--      <el-button-->
<!--          icon="el-icon-plus"-->
<!--          type="primary"-->
<!--          @click="dialog = true"-->
<!--          v-if="addShow && addRoot"-->
<!--      >-->
<!--        添加-->
<!--      </el-button>-->
    </div>
    <div v-if="queryRoot">
      <el-row>
        <el-col :span="24" class="item">
          <p class="label">审核状态</p>
          <el-select v-model="checkStatus" size="medium" clearable>
            <el-option
                v-for="(item, index) in auditStateOption"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
          <el-button type="primary" @click="query">查询</el-button>
        </el-col>
      </el-row>
      <el-table
          :style="{ marginTop: '20px' }"
          :data="tableData"
          :max-height="500"
          :height="500"
          ref="tableRef"
          stripe
          v-loading="loading"
      >
        <el-table-column label="序号" :width="60" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (current - 1) * pageSize + 1 }} </span>
          </template>
        </el-table-column>
        <el-table-column
            v-for="(item, index) in columns"
            :prop="item.prop"
            :key="index"
            :label="item.label"
            align="center"
            :min-width="item.width"
        ></el-table-column>
      </el-table>
      <el-pagination
          class="pagination"
          background
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <no-root v-else></no-root>
    <el-dialog :visible.sync="dialog" title="新增学籍异动申请" width="30%">
      <el-form
          :model="dialogData"
          ref="stuMoveRef"
          :rules="rules"
          label-width="90px"
      >
        <el-form-item label="新专业" prop="newProfId">
          <el-select v-model="dialogData.newProfId" clearable>
            <el-option
                v-for="(item, index) in newProfessionalOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="新培养方式" prop="newTrainingMode">
          <el-input
              v-model="dialogData.newTrainingMode"
              clearable
              placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button
            type="primary"
            :loading="btnLoading"
            @click="confirm('stuMoveRef')"
        >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  StudentChangeList,
  StudentChangeAdd,
  QueryEduChange,
} from "@/libs/api/student.js";
export default {
  name: "student_move",
  data() {
    return {
      loading: false,
      btnLoading: false,
      checkStatus: null,
      auditStateOption: [
        { label: "等待审核", value: "0" },
        { label: "审核通过", value: "1" },
        { label: "驳回", value: "2" },
      ],
      tableData: [],
      columns: [
        { prop: "orgProfName", label: "原专业" },
        { prop: "newProfName", label: "新专业" },
        // { prop: "old_cultivate", label: "原培养方式" },
        // { prop: "new_cultivate", label: "新培养方式" },
        { prop: "changeTime", label: "异动时间", width: 120 },
        { prop: "checkStatus", label: "审核状态" },
      ],
      pageSize: 10,
      current: 1,
      total: 0,

      dialog: false,
      dialogData: {
        newProfId: null,
        newTrainingMode: null,
      },
      rules: {
        newProfId: [
          { required: true, message: "请选择新专业", trigger: "blur" },
        ],
      },
      newProfessionalOptions: [
        { label: "test", value: "0" },
        { label: "电子信息与技术", value: "1" },
        { label: "马克思主义思想", value: "2" },
      ],
      addShow: false, // 添加按钮是否显示
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    queryRoot() {
      return this.$sysPerKey("stuStudentChange:list");
    },
    queryEduChangeRoot() {
      return this.$sysPerKey("eduChange:queryEduChange1");
    },
    addRoot() {
      return this.$sysPerKey("stuStudentChange:add");
    },
  },
  watch: {
    queryRoot(n) {
      if (n) {
        this.init();
      }
    },
    queryEduChangeRoot(n) {
      n && this.queryEduChange();
    },
  },
  mounted() {
    this.queryRoot && this.init();
    this.queryEduChangeRoot && this.queryEduChange();
  },
  methods: {
    query() {
      this.init();
    },
    queryEduChange() {
      QueryEduChange().then((res) => {
        if (res && res.data) {
          const { beginTime, endTime } = res.data;
          let curTime = new Date();
          //把字符串格式转化为日期类
          let start = new Date(Date.parse(beginTime));
          let end = new Date(Date.parse(endTime));
          this.addShow = curTime >= start && curTime <= end ? true : false;
        }
      });
    },
    init() {
      this.loading = true;
      let data = new FormData();
      this.checkStatus && data.append("checkStatus", this.checkStatus);
      StudentChangeList(
          {
            page: this.current,
            pageSize: this.pageSize,
          },
          data
      )
          .then((res) => {
            this.loading = false;
            if (res.data) {
              this.tableData = res.data.list;
              this.total = res.data.total;
            }
          })
          .catch(() => {
            this.loading = false;
          });
    },
    handleCurrentChange(page) {
      if (page) {
        this.current = page;
        this.init();
      }
    },

    confirm(refName) {
      this.$refs[refName].validate((valid) => {
        if (!valid) return false;
        let data = new FormData();
        data.append("newProfId", this.dialogData.newProfId);
        data.append("newTrainingMode", this.dialogData.newTrainingMode);
        data.append("studentId", this.user.id);
        this.btnLoading = true;
        StudentChangeAdd(data)
            .then((res) => {
              if (res.code === 2000) {
                this.dialog = false;
                this.init();
              }
              this.btnLoading = false;
            })
            .catch(() => {
              this.btnLoading = false;
            });
      });
    },
  },
};
</script>
